import React from 'react'
import { LogoSvg } from '../svg'

export default function Nav() {
  return (
    <nav className='flex w-full bg-transparent justify-between items-center px-[70px] py-[10px]'>
      {LogoSvg}
      <a href='https://bluewallet.io/docs/' target='_blank'>
        <h1 className='text-white text-[14px] font-semibold cursor-pointer hover:text-blue-500'>Support</h1>
      </a>
    </nav>
  )
}
