import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Body() {

    const navigate = useNavigate();

  return (
    <div className='flex flex-col justify-center px-[30px] py-[15px]'>
        <div className='flex flex-col gap-[10px] items-start absolute top-[150px] w-[300px] rounded-[10px] px-[15px] py-[10px] justify-start bg-[#1a1a1a]'>
            <h1 className='text-[20px] text-white'>Add a wallet</h1>
            <p className='text-[14px] text-gray-500 text-start w-[180px]'>It's free, and you can create as many as you like.</p>
            <button 
            onClick={() => navigate('/wallet')}
            className='px-[15px] py-[5px] rounded-[8px] text-black bg-blue-500 hover:text-white'>Add now</button>
        </div>
    </div>
  )
}
