import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as bip39 from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';

export default function Create() {
    const [clsCrt, setClsCrt] = useState();
    const navigate = useNavigate();
    const [phrases, setPhrases] = useState([]);
    const mnemonic = bip39.generateMnemonic(wordlist);

    useEffect(() => {
        if (mnemonic) {
            setPhrases(mnemonic.split(' '));
        }
    }, []);
    return (
        <div className="flex flex-col gap-[20px] ml-[20px] mt-[70px]">
            <div
                className={`flex flex-col bg-[#121212] px-[30px] py-[25px] w-full max-w-[400px] rounded-[8px] gap-[15px] ${clsCrt ? 'slide-down' : 'slide-up'
                    }`}
            >
                <h1 className="text-white text-[16px] text-center font-semibold">
                    Your wallet has been created.
                </h1>
                <p className="text-white text-[12px] font-semibold">
                    Please take a moment to write down this mnemonic phrase on a piece of paper.{' '}
                    <span className="block">
                        It's your backup, and you can use it to recover the wallet.
                    </span>
                </p>

                <div className="flex flex-wrap gap-[10px] mt-[10px]">
                    {phrases.map((word, index) => (
                        <div
                            key={index}
                            className="flex items-center bg-[#262626] px-[10px] py-[5px] rounded-[10px] text-white text-[14px] gap-[5px] shadow-md"
                        >
                            <span className="text-white flex items-center justify-center text-[12px] font-semibold">
                                {index + 1}
                            </span>
                            {word}
                        </div>
                    ))}
                </div>

                <button
                    onClick={() => {
                        setClsCrt(true);
                        setTimeout(() => navigate('/wallet'), 500);
                    }}
                    className='px-[15px] py-[10px] bg-blue-500 mx-auto mt-[40px] rounded-[30px] text-white text-[14px]'>OK, I wrote it down.</button>
            </div>
        </div>
    );
}
