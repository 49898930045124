import './App.css';
import Nav from './components/Nav';
import Body from './components/Body';
import Wallet from './components/Wallet';
import Import from './components/Import';
import Create from './components/Create';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="relative flex flex-col">
      <div className="w-full fixed top-0 left-0 z-10">
        <Nav />
      </div>

      <div className="flex justify-between pt-[60px]">
        <div className="flex-1">
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/import" element={<Import />} />
            <Route path="/create" element={<Create />} />
          </Routes>
        </div>

        <div className="hidden md:block absolute right-[200px] top-[100px] bouncing-image">
          <img src="/asset/bluewallet.png" alt="phone" className="h-[500px]" />
        </div>
      </div>



      <div className='absolute top-[700px] w-full'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
