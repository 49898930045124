import React from 'react';

export default function Footer() {
    const footerLinks = ['Privacy Policy', 'Terms of Service', 'Contact Us', 'About Us'];
    const items = [
        { logo: 'asset/twitter.svg', title: 'Twitter' },
        { logo: 'asset/telegram.svg', title: 'Telegram' },
        { logo: 'asset/github.svg', title: 'Github' },
    ];

    return (
        <div className="flex flex-col w-full gap-[10px] px-[30px] pb-[30px]">
            <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start">
                <p className="text-white text-center md:text-left text-[14px]">
                    <span className="text-[#6b7990] font-semibold text-[16px]">BlueWallet</span> was forged in the ashes of the 2017 fork wars,{' '}
                    <span className="block">in order to bring a powerful and Bitcoin-only wallet for the community</span>
                </p>
                <div className="hidden md:flex gap-[50px]">
                    <a
                        href="https://apps.apple.com/us/app/bluewallet-bitcoin-wallet/id1376878040"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src="asset/app-store.svg" alt="app" className="w-[200px] cursor-pointer" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=io.bluewallet.bluewallet"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src="asset/play-store.svg" alt="play" className="w-[200px] cursor-pointer" />
                    </a>
                </div>
            </div>
            <div className="flex flex-col mt-[40px] gap-[20px]">
                <h4 className="text-[1.125rem] leading-[1.4] text-[#fff] text-center self-start">© BlueWallet</h4>
                <div className="flex flex-col md:flex-row md:justify-between items-center gap-[20px] w-full">
                    <div className="flex gap-[20px] justify-center md:justify-start">
                        {footerLinks.map((link, index) => (
                            <a
                                key={index}
                                href="#"
                                className="text-[#6b7990] text-[14px] hover:text-[#ffffff] transition-colors"
                            >
                                {link}
                            </a>
                        ))}
                    </div>
                    <div className="flex flex-row md:flex-row gap-[10px] justify-center items-center">
                        {items.map((item, index) => (
                            <div
                                key={index}
                                className="flex cursor-pointer items-center gap-[15px] px-[30px] py-[15px] bg-[#121212] hover:bg-[#262626] rounded-[4px]"
                            >
                                <img src={item.logo} alt={item.title} className="w-[20px] h-[20px]" />
                                <span className="text-white text-[14px]">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
