import React, { useState } from 'react';
import { CloseSvg } from '../svg';
import { useNavigate } from 'react-router-dom';

export default function Wallet() {
    const navigate = useNavigate();
    const [clsWlt, setClsWlt] = useState(false);
    const [activeWallet, setActiveWallet] = useState(null); 

    const walletData = [
        {
            id: 1,
            imgSrc: '/asset/bitcoin.png',
            title: 'Bitcoin',
            description: 'Simple and powerful Bitcoin wallet',
        },
        {
            id: 2,
            imgSrc: '/asset/multi-vult.png',
            title: 'Multisig Vault',
            description: 'Best security for large amounts',
        },
    ];

    return (
        <div className="flex flex-col ml-[20px] mt-[70px]">
            <div
                className={`flex flex-col bg-[#121212] px-[30px] py-[25px] w-full max-w-[400px] rounded-[8px] gap-[20px] ${
                    clsWlt ? 'slide-down' : 'slide-up'
                }`}
            >
                <div
                    onClick={() => {
                        setClsWlt(true);
                        setTimeout(() => navigate('/'), 500);
                    }}
                >
                    {CloseSvg}
                </div>
                <h1 className="text-[20px] text-white text-center">Add Wallet</h1>
                <div className="flex flex-col gap-[8px]">
                    <h1 className="text-[14px] text-white items-start self-start">Name</h1>
                    <input
                        className="w-full bg-[#262626] text-white placeholder:text-[14px] rounded-[8px] px-[20px] py-[7px]"
                        placeholder="my first wallet"
                    />
                </div>
                <div className="flex flex-col w-full gap-[8px]">
                    <h1 className="text-[14px] text-white items-start self-start">Type</h1>
                    {walletData.map((wallet, index) => (
                        <div
                            key={wallet.id}
                            className={`flex ${index === 1 ? "cursor-not-allowed" : "cursor-pointer"} w-full gap-[5px] items-center bg-[#3a3a3c] rounded-[8px] px-[10px] py-[2px] ${
                                activeWallet === wallet.id
                                    ? index === 1
                                        ? 'border-[2px] border-white'
                                        : 'border-[2px] border-blue-500'
                                    : 'border-[2px] border-transparent'
                            }`}
                            onClick={() => setActiveWallet(wallet.id)}
                        >
                            <img src={wallet.imgSrc} alt="logo" className="w-[40px] h-[40px]" />
                            <div className="flex flex-col self-start items-start">
                                <h1 className={`text-[16px] ${wallet.id === 2 ? 'text-white' : 'text-blue-500'} font-semibold`}>
                                    {wallet.title}
                                </h1>
                                <p className="text-[12px] text-gray-500">{wallet.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col gap-[10px] mt-[10px]">
                    <button 
                    onClick={() => navigate('/create')}
                    className="px-[20px] py-[7px] bg-[#046dfe] text-white w-full rounded-[30px]">Create</button>
                    <button
                        onClick={() => navigate('/import')}
                        className="text-white text-[14px] hover:underline cursor-pointer text-center mb-[30px]"
                    >
                        Import wallet
                    </button>
                </div>
            </div>
        </div>
    );
}
